import React from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"

export const withHero = Component => ({
  name = "Hero",
  analytics = {},
  image: card,
  ratio,
}) => {
  const {
    trackPromoImpression,
    trackPromoClick,
    VisibilitySensor,
  } = useAnalytics()

  Component.displayName = name
  return (
    <VisibilitySensor
      onChange={visible => visible && trackPromoImpression({ ...analytics })}
    >
      <Component
        card={card}
        ratio={ratio}
        handlePromoClick={() => trackPromoClick({ ...analytics })}
      />
    </VisibilitySensor>
  )
}
